<template>
  <div class="activity">
    <Header />
    <HeaderBottomOrders />
    <div class="investment-activity">
      <b-container class="activity-container">
        <div class="activity-title mb-4">
          <b-row class="justify-content-center">
            <h1>Withdraw</h1>
          </b-row>
        </div>
        <div>
          <div class="activity-tab">
            <b-row class="justify-content-center">
              <div class="on-proccess mx-4" :class="{ finish: tabActive === 'dalamProses' }">
                <b-col>
                  <h3><b-link @click="tabActive = 'dalamProses'">Dalam Proses</b-link></h3>
                </b-col>
              </div>
              <div class="on-proccess mx-4" :class="{ finish: tabActive === 'selesai' }">
                <b-col>
                  <h3><b-link @click="tabActive = 'selesai'">Selesai</b-link></h3>
                </b-col>
              </div>
            </b-row>
          </div>
        </div>
      </b-container>
    </div>
    <div class="list-activity">
      <b-container class="list-activity-container">
        <b-skeleton-wrapper :loading="false">
          <div class="contentCustom">
            <div class="back">
              <p @click="handleBack">Kembali</p>
            </div>
            <div class="body">
              <p class="heading">
                Withdraw
              </p>

              <!-- <div class="selectOption">
                <b-select>
                  <b-select-option>1 Bulan Terakhir</b-select-option>
                </b-select>
              </div> -->

              <div class="contantInformation jarak">
                <div class="item">
                  <h1 v-if="error">{{ error }}</h1>
                  <b-spinner v-else-if="loading" style="width: 50px; height: 50px; margin: auto;"></b-spinner>
                  <template v-else>
                    <!-- <h1 v-if="dataActive.length > 0">{{ rangeDate.startDate }} - {{ rangeDate.endDate }} :</h1> -->
                    <div class="content">
                      <div class="information" v-for="item in dataActive" :key="item.id">
                        <div class="main">
                          <img v-if="item.status == 0 || item.status == 5" class="logo"
                            src="@/assets/img/icons/circle/orange-circle.svg" alt="" />
                          <img v-else-if="item.status == 2 || item.status == 4" class="logo"
                            src="@/assets/img/icons/circle/red-circle.svg" alt="" />
                          <img v-else-if="item.status == 3" class="logo" src="@/assets/img/icons/circle/green-circle.svg"
                            alt="" />

                          <div class="content">
                            <div class="first">
                              <p v-if="item.status == 0 || item.status == 5">Withdraw Pending</p>
                              <p v-else-if="item.status == 2">Withdraw Reject</p>
                              <p v-else-if="item.status == 3">Withdraw Success</p>
                              <p v-else-if="item.status == 4">Withdraw Cancel</p>
                              <p>{{ moment(item.created_at).format('DD MMMM YYYY, hh:mm:ss') }}</p>
                              <p class="warning" v-if="item.status == 5">Pembatalan anda di tolak</p>
                            </div>
                            <div class="last">
                              <p>{{ formatMoney(item.amount) }}</p>
                              <p>{{ user.individual.accounts[0]?.banks?.bank_name || "-" }}</p>
                            </div>
                          </div>
                        </div>
                        <!-- Batalkan -->
                        <div v-if="item.status == 0" class="action">
                          <b-button class='btn-secondary'
                            @click="cancelTransaction(item.transaction_id)">Batalkan</b-button>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </b-container>
    </div>
    <Footer3 />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomOrders from "@/components/partials/header/HeaderBottomOrders.vue";
import Footer3 from "@/components/partials/footer/Footer3.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import numeral from "numeral";
import axios from "axios";
const instance = axios.create({
  withCredentials: true,
});


export default {
  name: "withdrawHistory",
  metaInfo: {
    title: "Riwayat Withdraw | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomOrders,
    Footer3,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      app_url: "app_url",
    }),
    dataActive() {
      return this.tabActive === 'dalamProses' ? this.history.onGoing : this.history.finish;
    },
    // rangeDate() {
    //   const test = [...this.dataActive].sort((a, b) => {
    //     return moment(a.created_at).unix() - moment(b.created_at).unix()
    //   }).map(candidate => moment(candidate.date).format('DD MMMM YYYY'));

    //   return {
    //     startDate: test[0] || "-",
    //     endDate: test[test.length - 1] || "-",
    //   }
    // }
  },
  async mounted() {
    if (this.user?.individual?.is_fbonds !== 1) {
      this.$router.push('/portfolio')
    }

    await this.requestDone();
  },
  data() {
    return {
      tabActive: 'dalamProses',
      history: {
        onGoing: [],
        finish: []
      },
      loading: false,
      error: false,
    };
  },
  methods: {
    handleBack() {
      this.$router.push('/withdraw')
    },
    formatMoney(value) {
      return numeral(parseFloat(value)).format('0,0.[0000]')
    },
    cancelTransaction(id) {
      if (!id) {
        alert("Invalid ID");
      }

      this.loading = true;
      instance({
        url: `${this.app_url}fbonds/cash-withdrawals-status`,
        method: 'POST',
        data: {
          type: 'frontend',
          transaction_id: id,
          status: '4',
          date: moment().format('DD/MM/YYYY')
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        }
      }).then(async () => {
        await this.requestDone();
        alert("Berhasil");
      }).catch(err => {
        console.log(err);
        alert("Terjadi Kesalahan");
      }).finally(() => {
        this.loading = false;
      })
    },
    async requestDone() {
      try {
        // this.history.onGoing = [
        //   {
        //     "id": 58,
        //     "date": "2023-11-17",
        //     "client_id": "RFIL0191",
        //     "amount": "100001.00",
        //     "description": "Cashout",
        //     "bank_account_no": "4584271550",
        //     "transaction_id": "1_17112023_5",
        //     "client_id_2": "RFIL0191",
        //     "status": 0,
        //     "created_by": 4116,
        //     "updated_by": 4116,
        //     "created_at": "2023-11-17T03:23:50.000000Z",
        //     "updated_at": "2023-11-17T03:23:50.000000Z"
        //   },
        //   {
        //     "id": 59,
        //     "date": "2023-11-17",
        //     "client_id": "RFIL: 00191",
        //     "amount": "100001.00",
        //     "description": "Cashout",
        //     "bank_account_no": "4584271550",
        //     "transaction_id": "1_17112023_6",
        //     "client_id_2": "RFIL0191",
        //     "status": 3,
        //     "created_by": 4116,
        //     "updated_by": 4116,
        //     "created_at": "2023-11-17T03:24:27.000000Z",
        //     "updated_at": "2023-11-17T03:24:27.000000Z"
        //   },
        //   {
        //     "id": 60,
        //     "date": "2023-11-17",
        //     "client_id": "RFIL0191",
        //     "amount": "100001.00",
        //     "description": "Cashout",
        //     "bank_account_no": "4584271550",
        //     "transaction_id": "1_17112023_7",
        //     "client_id_2": "RFIL0191",
        //     "status": 3,
        //     "created_by": 4116,
        //     "updated_by": 4116,
        //     "created_at": "2023-11-17T03:27:32.000000Z",
        //     "updated_at": "2023-11-17T03:27:32.000000Z"
        //   },
        //   {
        //     "id": 71,
        //     "date": "2023-11-22",
        //     "client_id": "RFII0847",
        //     "amount": "201010.00",
        //     "description": "Cashout",
        //     "bank_account_no": "4588133956",
        //     "transaction_id": "1_22112023_12",
        //     "client_id_2": "RFII0847",
        //     "status": 5,
        //     "created_by": 4136,
        //     "updated_by": 4136,
        //     "created_at": "2023-11-22T09:58:36.000000Z",
        //     "updated_at": "2023-11-22T09:58:36.000000Z"
        //   },
        //   {
        //     "id": 70,
        //     "date": "2023-11-22",
        //     "client_id": "RFII0847",
        //     "amount": "100010.00",
        //     "description": "Cashout",
        //     "bank_account_no": "4588133956",
        //     "transaction_id": "1_22112023_11",
        //     "client_id_2": "RFII0847",
        //     "status": 2,
        //     "created_by": 4136,
        //     "updated_by": 4136,
        //     "created_at": "2023-11-22T06:59:57.000000Z",
        //     "updated_at": "2023-11-22T06:59:57.000000Z"
        //   }
        // ]
        this.loading = true;

        let responseOnGoing = await instance({
          method: "GET",
          url: `${this.app_url}fbonds/cash-withdrawals-list?status=0&client_id=${this.user.individual.client_id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });

        let responseReject = await instance({
          method: "GET",
          url: `${this.app_url}fbonds/cash-withdrawals-list?status=5&client_id=${this.user.individual.client_id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });

        this.history.onGoing = [...responseOnGoing.data.data, ...responseReject.data.data];

        let responseError = await instance({
          method: "GET",
          url: `${this.app_url}fbonds/cash-withdrawals-list?status=2&client_id=${this.user.individual.client_id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });

        let responseSuccess = await instance({
          method: "GET",
          url: `${this.app_url}fbonds/cash-withdrawals-list?status=3&client_id=${this.user.individual.client_id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });

        let responseSuccessError = await instance({
          method: "GET",
          url: `${this.app_url}fbonds/cash-withdrawals-list?status=4&client_id=${this.user.individual.client_id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });

        this.history.finish = [...responseSuccess.data.data, ...responseError.data.data, ...responseSuccessError.data.data];

        this.loading = false;
      } catch (err) {
        console.log(err);
        this.error = "Error when request data to server";
      }
    }
  } // /api/fbonds/cash-withdrawals-list?status=1&client_id=2407
};
</script>

<style lang="scss" scoped>
.contentCustom {
  margin-top: 30px;
  width: 500px;
  margin: 30px auto auto;

  .back {
    p {
      color: #6B7280;
      /* Text Desktop Base/Bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.5;
      }

    }
  }

  .body {
    padding: 35px 35px 200px;
    background-color: white;

    .selectOption {
      width: max-content;
      margin-top: 50px;
      margin-left: auto;

      select {
        background-color: #F5F5F5;
        color: var(--neutral-01, #3D3D3D);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        border: 0;
      }
    }

    .heading {
      color: #111827;
      text-align: center;
      /* Text Desktop LG/Bold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .contantInformation {
      display: grid;
      gap: 44px;

      .item {
        display: grid;
        gap: 20px;

        h1 {
          color: var(--neutral-01, #3D3D3D);
          text-align: left;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }

        .content {
          display: grid;
          gap: 16px;

          .information {
            background-color: #F5F5F5;
            border-radius: 6px;
            padding: 10px;
            transition: 0.5s;

            .main {
              display: flex;
              justify-content: space-between;
              gap: 12px;
              align-items: center;

              .logo {
                width: 43px;
                height: 43px;
                flex-shrink: 0;
              }

              p {
                margin: 0;
              }

              .content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .first {
                  p:first-child {
                    color: #000;
                    text-align: left;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                  }

                  p {
                    color: var(--neutral-03, #666);
                    text-align: left;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    /* 18px */
                  }

                  p.warning {
                    color: red !important;
                  }
                }

                .last {
                  p:first-child {
                    color: #000;
                    text-align: right;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                  }

                  p:last-child {
                    color: var(--neutral-03, #666);
                    text-align: right;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    /* 18px */
                  }
                }
              }

              p:first-child {
                color: #374151;
                /* Text Desktop Base/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
              }

              p:last-child {
                color: #111827;
                text-align: right;
                /* Text Desktop Base/Bold */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
              }
            }

            .action {
              margin-top: 20px;
              width: 100%;

              button {
                margin: 0 0 0 auto;
                display: block;
                // background-color: transparent;
                // outline: transparent;
                // border: none;
                // appearance: none;
                // transition: .1s;
              }

              // button:hover {
              //   text-decoration: underline;
              // }

              // button:active {
              //   opacity: 0.7;
              //   transform: scale(0.9);
              // }
            }

            .inform {
              color: #374151;
              /* Text Desktop Base/Regular */
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }

            .divider {
              width: 100%;
              height: 1px;
              background-color: #E5E7EB;
            }
          }
        }
      }
    }
  }

  .jarak {
    margin-top: 59px;
  }
}

.activity {
  background: #f3f4f6;
}

.investment-activity {
  background: white;
}

.activity-container {
  padding-top: 120px;
  margin-bottom: 0px;
}

.list-activity-card {
  box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
    0px 1px 3px rgba(107, 114, 128, 0.15);
  border-radius: 6px;
}

.list-activity {
  background: #f3f4f6;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

.activity-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    color: #111827;
  }
}

.transaction-date {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-type {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-promo {
  font-weight: 700;
  font-size: 12px;
  color: #2c5282;
  background: #ebf8ff;
  border-radius: 6px;
}

.transaction-status {
  font-weight: 700;
  font-size: 12px;
  color: #ce2512;
  background: #fdeeec;
  border-radius: 20px;
}

.transaction-status-success {
  font-weight: 700;
  font-size: 12px;
  color: #2f855a;
  background: #f0fff4;
  border-radius: 20px;
}

.transaction-status-failed {
  font-weight: 700;
  font-size: 12px;
  color: #ce2512;
  background: #fdeeec;
  border-radius: 20px;
}

.fund-padding {
  margin-left: 20px;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    color: #1f2937;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: #6b7280;
  }
}

.investment-value {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.purchased {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.on-proccess {
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.finish {
  border-bottom: 3px solid $tertiaryColor;

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.order-subscription span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #2f855a;
  background: #c6f6d5;
  border-radius: 40px;
  padding: 4px 12px 4px 12px;
}

.order-redeem span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #c53030;
  background: #fed7d7;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.order-switching span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #c05621;
  background: #feebc8;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.order-reinvestment span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #2a4365;
  background: #ebf8ff;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.timer-buy {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.switch-from {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.switch-to {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.btn-modal-portfolio {
  font-family: $inter;
  background: transparent;
  padding: 0;
  border: none;
}

.btn-modal-portfolio:hover {
  background: transparent !important;
}

.btn-modal-portfolio:focus {
  box-shadow: none !important;
  background: transparent !important;
}

.btn-modal-portfolio:active {
  background: transparent !important;
  outline: none !important;
}

@media only screen and (max-width: 768px) {

  // img {
  //   display: none;
  // }
  h4 {
    font-size: 14px !important;
  }

  h3 {
    font-size: 14px !important;
  }

  .estimation {
    p {
      text-align: left !important;
    }

    h3 {
      text-align: left !important;
    }
  }
}
</style>